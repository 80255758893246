import React, { useEffect, useState, useRef, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { Box, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

const BTCPriceChart = () => {
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [cryptoInfo, setCryptoInfo] = useState({ price: 0, change: 0 });
  const [selectedTimeframe, setSelectedTimeframe] = useState('30');
  const [fullHistoryData, setFullHistoryData] = useState([]);
  const theme = useTheme();

  // Fetch data based on the selected timeframe
  const fetchBitcoinData = async (days) => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin/market_chart', {
        params: {
          vs_currency: 'usd',
          days,
        },
      });

      // Convert prices to timestamps (in milliseconds) and prices
      const prices = response.data.prices.map((price) => [
        price[0], // Timestamp in milliseconds
        price[1], // Price in USD
      ]);

      setFullHistoryData(prices); // Store full data in state
    } catch (error) {
      console.error('Error fetching BTC data:', error);
    }
  };

  const fetchCryptoInfo = async () => {
    try {
      const currentData = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin');
      const currentPrice = currentData.data.market_data.current_price.usd;
      const priceChange24h = currentData.data.market_data.price_change_percentage_24h;
      setCryptoInfo({ price: currentPrice, change: priceChange24h });
    } catch (error) {
      console.error('Error fetching Bitcoin data:', error);
    }
  };

  // Memoize filterDataByTimeframe using useCallback
  const filterDataByTimeframe = useCallback((days) => {
    const now = Date.now();
    let timeframeMilliseconds;

    // Define time ranges for each timeframe
    switch (days) {
      case '1': // 1 day
        timeframeMilliseconds = 1 * 24 * 60 * 60 * 1000;
        break;
      case '7': // 1 week
        timeframeMilliseconds = 7 * 24 * 60 * 60 * 1000;
        break;
      case '30': // 1 month
        timeframeMilliseconds = 30 * 24 * 60 * 60 * 1000;
        break;
      case '90': // 3 months
        timeframeMilliseconds = 90 * 24 * 60 * 60 * 1000;
        break;
      case '365': // 1 year
        timeframeMilliseconds = 365 * 24 * 60 * 60 * 1000;
        break;
      default: // 'max' for full data
        return fullHistoryData;
    }

    // Filter data that falls within the selected timeframe
    const filteredData = fullHistoryData.filter((data) => now - data[0] <= timeframeMilliseconds);
    return filteredData;
  }, [fullHistoryData]);

  useEffect(() => {
    fetchBitcoinData(selectedTimeframe); // Fetch data when timeframe changes
    fetchCryptoInfo(); // Fetch the current price and change info
  }, [selectedTimeframe]);

  useEffect(() => {
    if (fullHistoryData.length > 0) {
      const prices = filterDataByTimeframe(selectedTimeframe);

      if (prices.length === 0) {
        console.warn('No data points available for the selected timeframe');
        return;
      }

      const minPrice = Math.min(...prices.map((p) => p[1]));
      const maxPrice = Math.max(...prices.map((p) => p[1]));

      setChartOptions({
        chart: {
          height: 300,
          backgroundColor: theme.palette.mode === 'light'? '#716969' : '#161616', // Background color of the chart
          marginBottom: 40,
          width: null, // Ensures the chart uses 100% of the container's width
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'datetime', // Ensure x-axis is based on time
          labels: {
            style: {
              color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090', // Set the label text color
            },
          },
        },
        yAxis: {
          visible: false,
          min: minPrice,
          max: maxPrice,
          startOnTick: false,
          endOnTick: false,
          minPadding: 0.4,
          maxPadding: 0.2,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          crosshairs: true,
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            className: 'popup-on-click',
            marker: {
              lineWidth: 1,
            },
          },
        },
        series: [
          {
            name: 'Bitcoin Price (USD)',
            data: prices, // Data should be in [timestamp, price] format
            color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090', // Curve color
            lineWidth: 4,
            marker: {
              radius: 4,
              fillColor: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090', // Marker color
            },
          },
        ],
        credits: {
          enabled: false, // Remove highcharts.com credits
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 600,
              },
              chartOptions: {
                chart: {
                  height: 200,
                },
              },
            },
          ],
        },
      });
    }
  }, [selectedTimeframe, fullHistoryData, filterDataByTimeframe, theme.palette.mode]);

  return (
    <Box style={{ backgroundColor: theme.palette.mode === 'light'? '#716969' : '#161616', padding: '20px', borderRadius: '18px', width: '100%', margin: '0 auto', overflow: 'hidden' }}>
      <Box style={{
        marginBottom: '20px',
        color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090', // Set text color for the title and price info
        textAlign: 'left',
      }}>
        <h2 style={{ margin: 0, fontSize: '36px' }}>Bitcoin</h2>
        <h3 style={{ margin: '5px 0', fontSize: '32px' }}>${cryptoInfo.price.toFixed(2)}</h3>
        <p style={{ margin: 0, fontSize: '24px', color: theme.palette.mode === 'light'? '#c4e87d' : '#4B6855' }}>
          {cryptoInfo.change >= 0 ? `+${cryptoInfo.change.toFixed(2)}%` : `${cryptoInfo.change.toFixed(2)}%`} (24h)
        </p>
      </Box>

      {chartOptions && (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            ref={chartRef}
            containerProps={{ style: { width: '100%', height: '100%' } }}
          />
          <Box style={{
            marginTop: '20px',
            textAlign: 'left',
          }}>
            {['1', '7', '30', '90', '365', 'max'].map((timeframe, index) => (
              <button
                key={index}
                onClick={() => setSelectedTimeframe(timeframe)}
                style={{
                  backgroundColor: selectedTimeframe === timeframe ? theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' : theme.palette.mode === 'light'? '#716969' : '#161616',
                  color: selectedTimeframe === timeframe ? '#605b5b' : theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090',
                  border: 'none',
                  borderRadius: '5px',
                  padding: window.innerWidth < 600 ? '8px 12px' : '10px 15px', // Smaller padding for mobile
                  margin: '0 5px', // Smaller margin for mobile
                  fontSize: window.innerWidth < 600 ? '13px' : '14px', // Smaller font size for mobile
                  cursor: 'pointer',
                }}
              >
                {timeframe === '1' ? '1D' : timeframe === '7' ? '1W' : timeframe === '30' ? '1M' : timeframe === '90' ? '3M' : timeframe === '365' ? '1Y' : 'ALL'}
              </button>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default BTCPriceChart;