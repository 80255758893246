import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme, useMediaQuery, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Box, Typography, Stack, Button, TextField, InputAdornment, Link } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSpring, animated } from 'react-spring';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import IconButton from '@mui/material/IconButton';
import PaymentsIcon from '@mui/icons-material/Payments';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PublicIcon from '@mui/icons-material/Public';
import SchemaIcon from '@mui/icons-material/Schema';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PaymentIcon from '@mui/icons-material/Payment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MouseIcon from '@mui/icons-material/Mouse';

import BoxReveal from "./magicui/BoxReveal";
import axios from 'axios';
import PriceChart from './PriceChart';
import TransactionsBox from './TransactionsBox';
import { FaBitcoin } from 'react-icons/fa';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

export default function Hero() {
  const theme = useTheme();
  const [latestPrice, setLatestPrice] = useState(null);
  const [formExpanded, setFormExpanded] = useState(false);
  const [uploadState, setUploadState] = useState({ front: 'idle', back: 'idle' });
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [progressState, setProgressState] = useState({ validating: 'idle', checking: 'idle', submitting: 'idle' });
  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const xs = useMediaQuery('(max-width:600px)');
  const md = useMediaQuery('(max-width:800px)');

  useEffect(() => {
    const fetchBitcoinData = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/coins/bitcoin/market_chart', {
          params: {
            vs_currency: 'usd',
            days: '30',
          },
        });

        const prices = response.data.prices.map((price) => ({
          x: new Date(price[0]),
          y: price[1],
        }));

        const latestPrice = prices[prices.length - 1]?.y || null;

        setLatestPrice(latestPrice);
      } catch (error) {
        console.error('Error fetching Bitcoin data:', error);
      }
    };

    fetchBitcoinData();
  }, []);

  const formik = useFormik({
    initialValues: {
      usdAmount: '',
      btcAmount: '',
      fullName: '',
      dob: '',
      ssn: '',
      email: '',
      bitcoinAddress: '',
      cardName: '',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
    },
    validationSchema: Yup.object({
      usdAmount: Yup.number().nullable().required('Required'),
      btcAmount: Yup.number().nullable().required('Required'),
      fullName: Yup.string().required('Required'),
      dob: Yup.date().required('Required'),
      ssn: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      bitcoinAddress: Yup.string().required('Required'),
      cardName: Yup.string().required('Required'),
      cardNumber: Yup.string().required('Required'),
      expirationDate: Yup.string().required('Required'),
      cvv: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      if (showPaymentForm) {
        handleConfirmPaymentClick();
      } else {
        setShowPaymentForm(true);
      }
    },
  });

  const handleUsdChange = (event) => {
    let usdValue = event.target.value;

    // Filter out non-numeric characters except for the decimal point
    usdValue = usdValue.replace(/[^0-9.]/g, '');

    // Allow leading zero only if it's followed by a decimal point
    if (usdValue.startsWith('0') && !usdValue.startsWith('0.')) {
      usdValue = usdValue.replace(/^0+(?=\d)/, '');
    }

    // Prevent multiple decimal points
    if ((usdValue.match(/\./g) || []).length > 1) {
      return;
    }

    // Restrict to 2 decimal places
    if (usdValue.includes('.')) {
      const [integerPart, decimalPart] = usdValue.split('.');
      usdValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
    }

    // Update btcAmount only when there's a valid input
    if (usdValue && !isNaN(usdValue)) {
      const btcValue = parseFloat(usdValue) / latestPrice;
      formik.setFieldValue('btcAmount', btcValue.toFixed(8));
    } else {
      formik.setFieldValue('btcAmount', '');
    }

    formik.setFieldValue('usdAmount', usdValue);
  };

  const handleBtcChange = (event) => {
    let btcValue = event.target.value;

    // Filter out non-numeric characters except for the decimal point
    btcValue = btcValue.replace(/[^0-9.]/g, '');

    // Allow leading zero only if it's followed by a decimal point
    if (btcValue.startsWith('0') && !btcValue.startsWith('0.')) {
      btcValue = btcValue.replace(/^0+(?=\d)/, '');
    }

    // Prevent multiple decimal points
    if ((btcValue.match(/\./g) || []).length > 1) {
      return;
    }

    // Restrict to 8 decimal places
    if (btcValue.includes('.')) {
      const [integerPart, decimalPart] = btcValue.split('.');
      btcValue = `${integerPart}.${decimalPart.substring(0, 8)}`;
    }

    // Update usdAmount only when there's a valid input
    if (btcValue && !isNaN(btcValue)) {
      const usdValue = parseFloat(btcValue) * latestPrice;
      formik.setFieldValue('usdAmount', usdValue.toFixed(2));
    } else {
      formik.setFieldValue('usdAmount', '');
    }

    formik.setFieldValue('btcAmount', btcValue);
  };

  const handleBuyNowClick = () => {
    setShowPaymentForm(false);
    setFormExpanded(true);
  };

  const handleGoBackClick = () => {
    if (showPaymentForm) {
      setShowPaymentForm(false);
      formik.setFieldValue('bitcoinAddress', '');
      formik.setFieldValue('cardName', '');
      formik.setFieldValue('cardNumber', '');
      formik.setFieldValue('expirationDate', '');
      formik.setFieldValue('cvv', '');

      formik.setTouched({
        bitcoinAddress: false,
        cardName: false,
        cardNumber: false,
        expirationDate: false,
        cvv: false,
      }, false);
    } else {
      setFormExpanded(false);
      formik.setFieldValue('fullName', '');
      formik.setFieldValue('dob', '');
      formik.setFieldValue('ssn', '');
      formik.setFieldValue('email', '');
      setUploadState({ front: 'idle', back: 'idle' });

      formik.setTouched({
        fullName: false,
        dob: false,
        ssn: false,
        email: false,
      }, false);
    }
  };

  const handleFileUpload = (side) => {
    setUploadState((prevState) => ({ ...prevState, [side]: 'uploading' }));

    setTimeout(() => {
      setUploadState((prevState) => ({ ...prevState, [side]: 'completed' }));
    }, 2000);
  };

  const handleConfirmPaymentClick = () => {
    setShowModal(true);
    setProgressState({ validating: 'inProgress', checking: 'inProgress', submitting: 'inProgress' });

    setTimeout(() => {
      setProgressState((prevState) => ({ ...prevState, validating: 'success' }));

      setTimeout(() => {
        setProgressState((prevState) => ({ ...prevState, checking: 'success' }));

        setTimeout(() => {
          setProgressState((prevState) => ({ ...prevState, submitting: 'success' }));
          setTimeout(() => {
            setShowModal(false);
            setOrderSubmitted(true);
          }, 500);
        }, 2000);
      }, 2000);
    }, 2000);
  };

  const handleOrderSubmittedGoBack = () => {
    setOrderSubmitted(false);
    setFormExpanded(false);
    setProgressState({ validating: 'idle', checking: 'idle', submitting: 'idle' });
    formik.resetForm();
    setUploadState({ front: 'idle', back: 'idle' });
  };

  const formSpring = useSpring({
    opacity: formExpanded ? 1 : 0,
    transform: formExpanded ? 'translateY(0)' : 'translateY(20px)',
    config: { tension: 280, friction: 60 },
  });

  const chartSpring = useSpring({
    opacity: formExpanded ? 0 : 1,
    transform: formExpanded ? 'translateY(-20px)' : 'translateY(0)',
    config: { tension: 280, friction: 60 },
  });

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        height: '100%',
        px: 2,
      }}
    >
      <Container
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: 'center',
          backgroundColor: theme.palette.mode === 'light'? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)',
          mt: { xs: 10, sm: 16 },
          mb: { xs: 10, sm: 30 },
          pb: { xs: 3, sm: 4 },
          px: { xs: 2, sm: 5 },
          pt: { xs: 2, sm: 3 },
          borderRadius: 5,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          transition: theme.transitions.create(
            ['background-color'],
            {
              duration: theme.transitions.duration.standard,
            }
          ),
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            py: { xs: 5, sm: 10 },
            pl: '10%',
            pr: { xs: '10%', sm: '20%', md: '40%' },
            my: { xs: 2, sm: 3 },
            borderRadius: 12,
            backgroundColor: theme.palette.mode === 'light'? '#716969' : '#161616',
          }}
        >
          <Box>
            <BoxReveal boxColor={"#d6f49d"} duration={0.5}>
              <Typography variant="h1" fontWeight="fontWeightBold" sx={{ fontSize: { xs: '4rem' }, color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' }}>
                Quick Coin<span style={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}>.</span>
              </Typography>
            </BoxReveal>

            <BoxReveal boxColor="#d6f49d" duration={0.5}>
              <Typography variant="h6" sx={{ mt: '0.5rem', ml: '0.5rem', fontSize: '2rem', color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' }}>
                Easy access to{' '}
                <Typography component="span" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F', fontSize: '2rem' }}>
                  Crypto-currencies
                </Typography>
              </Typography>
            </BoxReveal>

            <BoxReveal boxColor="#d6f49d" duration={0.5}>
              <Box sx={{ mt: '0.5rem', ml: '0.5rem' }}>
                <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' }}>
                  <EastIcon sx={{ verticalAlign: 'middle' }} /> 20+ coins and 48+ US states with
                  <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> fast transaction </Typography>
                  and
                  <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> low fees</Typography>.
                  <br />
                  <EastIcon sx={{ verticalAlign: 'middle' }} /> <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> Transparent </Typography> reviews,
                  <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> trackble </Typography> orders,
                  and
                  <Typography component="span" fontWeight="fontWeightBold" sx={{ color: theme.palette.mode === 'light'? '#d6f49d' : '#495E5F' }}> top secuirty </Typography>measures.
                  <br />
                </Typography>
              </Box>
            </BoxReveal>

            <BoxReveal boxColor={"#d6f49d"} duration={0.5} >
              <Button
                sx={{
                  mt: '1.6rem',
                  ml: '0.5rem',
                  backgroundColor: theme.palette.mode === 'light'? '#605B5B' : '#495E5F',
                  color: alpha('#f4f4f4',0.8),
                  borderRadius: '8px',
                  px: '1.2rem',
                  py: '0.3rem',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'light'? '#d6f49d' : '#4B6855',
                  },
                }}
              >
                Start
              </Button>
            </BoxReveal>
          </Box>
        </Box>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
          {!orderSubmitted ? (
            <Box sx={{display: 'flex', flexDirection: 'row', mt: 5}}>
              {(formExpanded && !md) && (
                <Timeline position="alternate">
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    Step 1
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <FingerprintIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2, color: theme.palette.mode === 'light'? showPaymentForm? '#605b5b' : 'inherit' : showPaymentForm? '#909090' : 'inherit', transition: 'color 0.3s ease-in-out', }}>
                    <Typography variant="h6" component="span">
                      Identity
                    </Typography>
                    <Typography>Help us know who you are</Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    Step 2
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <PaymentIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2, color: theme.palette.mode === 'light'? showPaymentForm? 'inherit' : '#605b5b' : showPaymentForm? 'inherit' : '#909090', transition: 'color 0.3s ease-in-out' }}>
                    <Typography variant="h6" component="span">
                      Payment
                    </Typography>
                    <Typography>Enter your payment information</Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    Step 3
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <HowToRegIcon />
                    </TimelineDot>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2, color: theme.palette.mode === 'light'? '#605b5b' : '#909090', transition: 'color 0.3s ease-in-out' }}>
                    <Typography variant="h6" component="span">
                      Live Check
                    </Typography>
                    <Typography>Help us confirm it's really you</Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                    <TimelineDot color="secondary">
                      <MouseIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      Submit
                    </Typography>
                    <Typography>The final step</Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
              )}
              <Box sx={{width: "100%"}}>
                <animated.div style={chartSpring}>
                  {!formExpanded && (
                    <Stack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        border: '3px solid',
                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#212121',
                        borderRadius: 5,
                        p: 2,
                        gap: 2,
                        mt: { xs: 0, sm: 4 },
                      }}
                    >
                      <TextField
                        hiddenLabel
                        size="medium"
                        variant="standard"
                        value={formik.values.usdAmount}
                        onChange={handleUsdChange}
                        placeholder="Amount in USD"
                        InputProps={{
                          startAdornment: <InputAdornment position="start" >
                              <Box sx={{ fontSize: '2rem', display: 'flex', alignItems: 'center', px: 0.75, color: theme.palette.mode === 'light' ? '#605b5b' : '#909090'}}>
                              $
                              </Box>
                            </InputAdornment>,
                          autoComplete: 'off',
                          sx: {
                            border: 'none',
                            borderBottom: '1px solid',
                            fontSize: {xs:'1.75rem', sm: '1.5rem', md:'1.75rem'},
                            color: theme.palette.mode === 'light' ? '#605B5B' : '#909090',
                          },
                          style: { textAlign: 'right' },
                        }}
                        sx={{
                          width: { xs: '90%', sm: '300px' },
                          '& .MuiInput-underline:before': {
                            borderBottomColor: theme.palette.mode === 'light' ? '#605B5B' : '#909090',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: theme.palette.mode === 'light' ? '#c4e87d' : '#4B6855',
                          },
                        }}
                        error={formik.touched.usdAmount && Boolean(formik.errors.usdAmount)}
                        helperText={formik.touched.usdAmount && formik.errors.usdAmount}
                      />
                      <TextField
                        hiddenLabel
                        size="medium"
                        variant="standard"
                        value={formik.values.btcAmount}
                        onChange={handleBtcChange}
                        placeholder="Amount in BTC"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FaBitcoin style={{ fontSize: '2rem', color: theme.palette.mode === 'light' ? '#605b5b' : '#909090' }}/>
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                          sx: {
                            border: 'none',
                            borderBottom: '1px solid',
                            fontSize: {xs:'1.75rem', sm: '1.5rem', md:'1.75rem'},
                            color: theme.palette.mode === 'light' ? '#605B5B' : '#909090',
                          },
                          style: { textAlign: 'right' },
                        }}
                        sx={{
                          width: { xs: '90%', sm: '300px' },
                          '& .MuiInput-underline:before': {
                            borderBottomColor: theme.palette.mode === 'light' ? '#605B5B' : '#909090',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: theme.palette.mode === 'light' ? '#c4e87d' : '#4B6855',
                          },
                        }}
                        error={formik.touched.btcAmount && Boolean(formik.errors.btcAmount)}
                        helperText={formik.touched.btcAmount && formik.errors.btcAmount}
                      />
                      <Button
                        sx={{
                          my: '1.5rem',
                          ml: '1.5rem',
                          backgroundColor: theme.palette.mode === 'light'? '#605B5B' : '#495E5F',
                          color: alpha('#f4f4f4',0.8),
                          borderRadius: 4,
                          fontSize: '1.25rem',
                          px: '1.5rem',
                          py: '0.75rem',
                          '&:hover': {
                            backgroundColor: theme.palette.mode === 'light'? '#d6f49d' : '#4B6855',
                          },
                        }}
                        variant="contained"
                        onClick={handleBuyNowClick}
                        disabled={!formik.values.usdAmount || !formik.values.btcAmount}
                      >
                        Buy Now
                      </Button>
                    </Box>
                    <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, mt: 2, fontSize: '1rem', color: '#605B5B'}}>
                        By clicking &quot;Buy Now&quot; you agree to our&nbsp;
                        <Link href="#" color={theme.palette.mode === 'light'? "#7C9388" : '#4B6855'}>
                          Terms & Conditions
                        </Link>
                        .
                    </Typography>
                    </Stack>
                  )}
                </animated.div>
                <animated.div style={formSpring}>
                  {formExpanded && (
                    <Box
                      sx={{
                        width: '100%',
                        p: {xs:2, md:4},
                        border: '3px solid',
                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                        borderRadius: 5,
                        transition: 'all 0.3s ease-in-out',
                      }}
                    >
                      <IconButton onClick={handleGoBackClick} sx={{ alignSelf: 'flex-start', mb: 2 }}>
                        <ArrowBackIcon sx={{color: theme.palette.mode === 'light' ? '#716969' : '#909090'}}/>
                      </IconButton>
                      <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2}>
                          {!showPaymentForm && (
                            <>
                              <TextField
                                label="Full Name"
                                variant="outlined"
                                fullWidth
                                required
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#A1A1A1',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: theme.palette.mode === 'light' ? '#7c9388' : '#4B6855',
                                  },
                                }}
                                {...formik.getFieldProps('fullName')}
                                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                                helperText={formik.touched.fullName && formik.errors.fullName}
                              />
                              <TextField
                                label="Date of Birth"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388',
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#7c9388', // Focused label color
                                  },
                                }}
                                {...formik.getFieldProps('dob')}
                                error={formik.touched.dob && Boolean(formik.errors.dob)}
                                helperText={formik.touched.dob && formik.errors.dob}
                              />
                              <TextField
                                label="SSN"
                                variant="outlined"
                                fullWidth
                                required
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: theme.palette.mode === 'light' ? '#7c9388' : '#4B6855',
                                  },
                                }}
                                {...formik.getFieldProps('ssn')}
                                error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                                helperText={formik.touched.ssn && formik.errors.ssn}
                              />
                              <Typography variant="body1" sx={{color: theme.palette.mode === 'light' ? '#605b5b' : '#909090', pl:1}}>
                                Upload Government Issued ID (both sides):
                              </Typography>
                              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                              <Button
                                variant="outlined"
                                component="label"
                                fullWidth
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  border: '3px solid',
                                  borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                  borderRadius: 5,
                                  color: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                  '&:hover': {
                                    borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1',
                                    backgroundColor: 'rgba(124, 147, 136, 0.1)',
                                  },
                                  '&.Mui-focused': {
                                    borderColor: '#7c9388',
                                    color: '#7c9388',
                                  },
                                }}
                              >
                                  {uploadState.front === 'uploading' && <CircularProgress size={24} sx={{color: theme.palette.mode === 'light' ?'#716969':'#909090'}} />}
                                  {uploadState.front === 'completed' && <CheckCircle sx={{ color: '#7c9388' }} />}
                                  {uploadState.front === 'idle' && 'Upload Front'}
                                  <input
                                    type="file"
                                    hidden
                                    onChange={() => handleFileUpload('front')}
                                  />
                                </Button>
                                <Button
                                  variant="outlined"
                                  component="label"
                                  fullWidth
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '3px solid',
                                    borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                    borderRadius: 5,
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                    '&:hover': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1',
                                      backgroundColor: 'rgba(124, 147, 136, 0.1)',
                                    },
                                    '&.Mui-focused': {
                                      borderColor: '#7c9388',
                                      color: '#7c9388',
                                    },
                                  }}
                                >
                                  {uploadState.back === 'uploading' && <CircularProgress size={24} sx={{color:theme.palette.mode === 'light' ?'#716969':'#909090'}} />}
                                  {uploadState.back === 'completed' && <CheckCircle sx={{ color: '#7c9388' }} />}
                                  {uploadState.back === 'idle' && 'Upload Back'}
                                  <input
                                    type="file"
                                    hidden
                                    onChange={() => handleFileUpload('back')}
                                  />
                                </Button>
                              </Stack>
                              <TextField
                                label="Email Address"
                                variant="outlined"
                                fullWidth
                                required
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#7c9388',
                                  },
                                }}
                                {...formik.getFieldProps('email')}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                              />
                              <Button
                                variant="contained"
                                sx={{
                                  mt: '1.6rem',
                                  ml: '0.5rem',
                                  backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : '#495E5F',
                                  color: alpha('#f4f4f4', 0.8),
                                  borderRadius: '8px',
                                  px: '1.2rem',
                                  py: '0.3rem',
                                  '&:hover': {
                                    backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : '#4B6855',
                                  },
                                }}
                                onClick={() => setShowPaymentForm(true)}
                                disabled={
                                  !formik.values.fullName ||
                                  !formik.values.dob ||
                                  !formik.values.ssn ||
                                  uploadState.front !== 'completed' ||
                                  uploadState.back !== 'completed' ||
                                  !formik.values.email
                                }
                              >
                                Next Step
                              </Button>
                            </>
                          )}
                          {showPaymentForm && (
                            <>
                              <TextField
                                label="Bitcoin Wallet Address"
                                variant="outlined"
                                fullWidth
                                required
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#7c9388', // Focused label color
                                  },
                                }}
                                {...formik.getFieldProps('bitcoinAddress')}
                                error={formik.touched.bitcoinAddress && Boolean(formik.errors.bitcoinAddress)}
                                helperText={formik.touched.bitcoinAddress && formik.errors.bitcoinAddress}
                              />
                              <Typography variant="body1" sx={{color: '#605b5b', pl:1}}>
                                Credit/Debit Card information:
                              </Typography>
                              <TextField
                                label="Cardholder Full Name"
                                variant="outlined"
                                fullWidth
                                required
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#7c9388', // Focused label color
                                  },
                                }}
                                {...formik.getFieldProps('cardName')}
                                error={formik.touched.cardName && Boolean(formik.errors.cardName)}
                                helperText={formik.touched.cardName && formik.errors.cardName}
                              />
                              <TextField
                                label="Card Number"
                                variant="outlined"
                                fullWidth
                                required
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: '3px solid',
                                      borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                      borderRadius: 5,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#7c9388', // Focus state color
                                    },
                                    '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                  },
                                  '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#7c9388', // Focused label color
                                  },
                                }}
                                {...formik.getFieldProps('cardNumber')}
                                error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
                                helperText={formik.touched.cardNumber && formik.errors.cardNumber}
                              />
                              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                                <TextField
                                  label="Expiration Date"
                                  variant="outlined"
                                  type="month"
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  required
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: '3px solid',
                                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                        borderRadius: 5,
                                      },
                                      '&:hover fieldset': {
                                        borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#7c9388', // Focus state color
                                      },
                                      '& input': {
                                      backgroundColor: 'transparent',
                                    },
                                    '& input:-webkit-autofill': {
                                      backgroundColor: 'transparent !important',
                                      WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                      transition: 'background-color 5000s ease-in-out 0s',
                                    },
                                    '& input:-internal-autofill-selected': {
                                      backgroundColor: 'transparent !important',
                                    },
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: '#7c9388', // Focused label color
                                    },
                                  }}
                                  {...formik.getFieldProps('expirationDate')}
                                  error={formik.touched.expirationDate && Boolean(formik.errors.expirationDate)}
                                  helperText={formik.touched.expirationDate && formik.errors.expirationDate}
                                />
                                <TextField
                                  label="CVV"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: '3px solid',
                                        borderColor: theme.palette.mode === 'light' ? '#716969' : '#909090',
                                        borderRadius: 5,
                                      },
                                      '&:hover fieldset': {
                                        borderColor: theme.palette.mode === 'light' ? '#605b5b' : '#a1a1a1', // Optional hover effect
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#7c9388', // Focus state color
                                      },
                                      '& input': {
                                      backgroundColor: 'transparent',
                                      },
                                      '& input:-webkit-autofill': {
                                        backgroundColor: 'transparent !important',
                                        WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                        transition: 'background-color 5000s ease-in-out 0s',
                                      },
                                      '& input:-internal-autofill-selected': {
                                        backgroundColor: 'transparent !important',
                                      },
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: theme.palette.mode === 'light' ? '#716969' : '#909090', // Default label color
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      color: '#7c9388', // Focused label color
                                    },
                                  }}
                                  {...formik.getFieldProps('cvv')}
                                  error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                                  helperText={formik.touched.cvv && formik.errors.cvv}
                                />
                              </Stack>
                              <Button
                                variant="contained"
                                sx={{
                                  mt: '1.6rem',
                                  ml: '0.5rem',
                                  backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : "#495E5F",
                                  color: '#f4f4f4',
                                  borderRadius: '8px',
                                  px: '1.2rem',
                                  py: '0.3rem',
                                  '&:hover': {
                                    backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : "#4B6855",
                                  },
                                }}
                                type="submit"
                                disabled={
                                  !formik.values.bitcoinAddress ||
                                  !formik.values.cardName ||
                                  !formik.values.cardNumber ||
                                  !formik.values.expirationDate ||
                                  !formik.values.cvv
                                }
                              >
                                Confirm Payment: ${formik.values.usdAmount}
                              </Button>
                            </>
                          )}
                        </Stack>
                      </form>
                    </Box>
                  )}
                </animated.div>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                px: 2,
                mt: 3,
                textAlign: 'center',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <LocalAtmIcon sx={{width:'2.5rem', height:'2.5rem', color: '#716969' }}/>
              <Typography variant="h3" sx={{ mb: 3, color: '#716969' }} >
                SUCCESS!
              </Typography>
              <Typography variant="body1" sx={{ mb: 1, color: '#716969' }}>
                Your order has been submitted for review.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: '#716969' }}>
                An email notification will be sent to xxx@gmail.com shortly.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: 3,
                  px: 8,
                  mb: 3,
                  border: '2px solid',
                  borderColor: '#716969',
                  borderRadius: 2,
                  textAlign: 'center',
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <Typography sx={{ color: '#716969' }} >
                  Order Number: #827361
                </Typography>
                <Typography sx={{ color: '#716969' }} >
                  Status: Under review
                </Typography>
              </Box>
              <Button
                variant="contained"
                sx={{
                  ml: '0.5rem',
                  backgroundColor: theme.palette.mode === 'light' ? '#605B5B' : "#495E5F",
                  color: '#f4f4f4',
                  borderRadius: '8px',
                  px: '1.2rem',
                  py: '0.3rem',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'light' ? '#c4e87d' : "#4B6855",
                  },
                }}
                onClick={handleOrderSubmittedGoBack}>
                Back to home
              </Button>
            </Box>
          )}
        </Stack>
        {!formExpanded && (
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              mt: 4,
            }}
          >

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' }, // Column on small screens, row on medium and larger screens
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' }, // Stack the child boxes on small screens, row on medium and up
                  width: '100%',
                  gap: { xs: 2, md: 5 }, // Smaller gap on small screens
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    borderColor: theme.palette.mode === 'light' ? '#716969' : '#212121',
                    borderWidth: '3px',
                    borderStyle: 'solid',
                    borderRadius: '20px',
                    boxSizing: 'border-box',
                    width: '100%',
                    height: '100%',
                    maxWidth: '537px',
                    mb: { xs: 2, lg: 0 }, // Add bottom margin on small screens to create spacing
                  }}
                >
                  <TransactionsBox />
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    boxSizing: 'border-box',
                    width: '100%',
                    maxWidth: '537px',
                  }}
                >
                  <PriceChart />
                </Box>
              </Box>
            </Box>


            <Typography
              variant="h2"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: theme.palette.mode === 'light'? '#605B5B' : '#909090',
                width: '100%',
                mt: 12,
              }}
            >
              Why Us?
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                px: 2,
                py: 4,
                mt: 5,
              }}
            >
              {/* Part 1 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <PaymentsIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' } }}>
                    Instant Payout
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' }, }}>
                    {xs? 'Get your crypto instantly' : 'Get your crypto instantly if approved and paid with debit card'}
                  </Typography>
                </Stack>
              </Box>

              {/* Part 2 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <SupportAgentIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' }  }}>
                    24/7 Support
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'Our support team is ready 24 by 7' : 'Our support team is alway accessible and ready to help 24 by 7'}
                  </Typography>
                </Stack>
              </Box>

              {/* Part 3 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <PublicIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' }  }}>
                    Nationwide access
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'Purchase cryptos accross US' : 'Purchase cryptos from 48 states accross the United States'}
                  </Typography>
                </Stack>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                px: 2,
                py: 4,
              }}
            >
              {/* Part 1 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <SchemaIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' } }}>
                    Effortless Checkouts
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'Simple purchasing process' : 'Simple purchasing process, offering easy and smooth experience'}
                  </Typography>
                </Stack>
              </Box>

              {/* Part 2 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <VerifiedUserIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' }  }}>
                    Top Privacy
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'We collect only necessary information' : 'We collect the minimum information required by regulations'}
                  </Typography>
                </Stack>
              </Box>

              {/* Part 3 */}
              <Box sx={{ flex: 1, textAlign: 'center', px: 2 }}>
                <Stack spacing={2} alignItems="center">
                  <AssuredWorkloadIcon sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: theme.palette.mode === 'light'? '#716969' : '#909090' }} />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.mode === 'light'? '#605b5b' : '#909090', fontSize: { xs: '1.25rem', md: '2rem' }  }}>
                    Secured Payments
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#666', fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    {xs? 'We process your payment safely' : 'We process your payment via secured gateways'}
                  </Typography>
                </Stack>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderColor: theme.palette.mode === 'light'? '#605b5b' : '#909090',
                borderWidth: '3px',
                borderStyle: 'solid',
                borderRadius: '20px',
                boxSizing: 'border-box',
                width: '100%',
                mt: 10,
              }}
            >
              <Box component="img"
                src={`/FinCEN.svg`}
                sx={{ width: 100, height: 100, my: 2, mr: {xs: 1, md: 2}, ml: {xs:'10%', md:'20%'} }}
              />
              <Box
                sx={{
                  flexDirection: 'column',
                  alignContent: 'center',
                  ml: 4,
                  p: 2,
                  pr: 4,
                }}
              >
                <Typography variant="h6" fontSize={'1.25rem'} sx={{ color: theme.palette.mode === 'light'? '#716969' : '#909090' }}>
                    {xs? 'FinCEN Department of the Treasury' : 'FinCEN Department of the Treasury, United States of America'}
                </Typography>
                <Typography variant="body1" sx={{ color: '#666' }}>
                    {xs? 'MSB Registration: 31000XXXXXXXXX' : 'Money Service Business Registration: 31000XXXXXXXXX'}
                </Typography>
              </Box>
            </Box>

          </Stack>
        )}
            <Box
              display= "flex"
              flexDirection= {{xs:'column', md:'row'}}
              sx={{
                backgroundColor: {xs: theme.palette.mode === 'light'? '#716969' : '#161616', md:'rgb(255,255,255,0)'},
                backgroundImage: theme.palette.mode === 'light'? 'url(/QCFooter.svg)' : 'url(/QCFooterDark.svg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '20px',
                width: '100%',
                pb: 4,
                px: 3,
                mt: 10,
              }}
            >
              <Box
                display= "flex"
                flexDirection= 'row'
              >
                <Box
                  display= "flex"
                  flexDirection= "column"
                  alignItems="flex-start"
                  marginTop={{xs:0, md:10}}
                  marginLeft={{xs:3, md:5, lg:8}}
                  marginRight={{xs:2, md:4, lg:6}}
                >
                  <Typography sx={{mt:8, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                    Contacts
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    support@quickcoin.com
                  </Typography>
                  <Typography sx={{mt: 5, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                    Address
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    QuickCoin LLC USA
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    Wyoming
                  </Typography>
                  {/* Add more components like buttons or links inside the Stack if needed */}
                </Box>

                <Box
                  display= "flex"
                  flexDirection= "column"
                  alignItems="flex-start"
                  marginTop={{xs:0, md:10}}
                  marginLeft={{xs:3, md:5, lg:8}}
                  marginRight={{xs:2, md:4, lg:6}}
                >
                  <Typography sx={{mt:8, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                    Information
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    About Us
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    News
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    FAQs
                  </Typography>
                  <Typography sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090'}}>
                    Privacy Policy
                  </Typography>
                  {/* Add more components like buttons or links inside the Stack if needed */}
                </Box>
              </Box>
              <Box
                display= "flex"
                flexDirection= "column"
                alignItems="flex-start"
                marginTop={{sm:0, md:10}}
                marginLeft={{xs:3, md:5, lg:8}}
              >
                <Typography sx={{mt:{xs:2, md:8}, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                  Social Media
                </Typography>
                <Box sx={{ color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090', display: 'flex', flexDirection: 'row'}}>
                  <XIcon sx={{height: '2rem', width: '2rem', mt: 1}}/>
                  <InstagramIcon sx={{height: '2rem', width: '2rem', mt: 1}}/>
                  <LinkedInIcon sx={{height: '2rem', width: '2rem', mt: 1}}/>
                </Box>
                <Typography sx={{mt: 2, color: theme.palette.mode === 'light'? '#979090' : '#676060'}}>
                  Track Order
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: {xs:'100%', sm:'60%', md:'75%', lg:'100%'},
                    backgroundColor: theme.palette.mode === 'light' ? '#716969' : '#161616',
                  }}
                >
                  <TextField
                    hiddenLabel
                    size="medium"
                    variant="standard"
                    placeholder="Order number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ fontSize: '1.5rem', display: 'flex', alignItems: 'center', px: 0.75, color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090' }}>
                            #
                          </Box>
                        </InputAdornment>
                      ),
                      autoComplete: 'off',
                      sx: {
                        border: 'none',
                        borderBottom: '1px solid',
                        fontSize: '1.5rem',
                        color: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090',
                      },
                      style: { textAlign: 'right' },
                    }}
                    sx={{
                      width: { xs: '90%', sm: '300px' },
                      '& .MuiInput-underline:before': {
                        borderBottomColor: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: theme.palette.mode === 'light'? '#c4e87d' : '#4B6855',
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: theme.palette.mode === 'light'? alpha('#f4f4f4',0.8) : '#909090',
                      },
                    }}
                  />
                  <Button
                    sx={{
                      ml: '1rem',
                      backgroundColor: theme.palette.mode === 'light'? '#605b5b' : '#495E5F',
                      color: alpha('#f4f4f4',0.8),
                      borderRadius: 2,
                      fontSize: '1rem',
                      mt: '0.4rem',
                      px: '0.8rem',
                      py: '0.3rem',
                      '&:hover': {
                        backgroundColor: theme.palette.mode === 'light'? '#c4e87d' : '#4B6855',
                      },
                    }}
                    variant="contained"
                  >
                    Track
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', color: '#716969', mt: 5, pl: 1 }}>
              <Typography sx={{fontSize:'0.9rem'}}>
                © 2024 QuickCoin.com, all rights reserved.
              </Typography>
            </Box>
      </Container>

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        PaperProps={{
          sx: {
            borderRadius: 4,
            backgroundColor: theme.palette.mode === 'light'? '#f4f4f4' : "#161616",
          },
        }}
      >
        <DialogTitle sx={{ color: theme.palette.mode === 'light'? '#716969' : "#909090" }}>Processing Payment</DialogTitle>
        <DialogContent sx={{ color: theme.palette.mode === 'light'? '#716969' : "#909090" }}>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {progressState.validating === 'inProgress' && <CircularProgress size={24} sx={{color:'#716969'}}/>}
              {progressState.validating === 'success' && <CheckCircle sx={{ color: '#7c9388' }} />}
              {progressState.validating === 'failed' && <Cancel sx={{ color: 'rgba(255, 0, 0, 0.7)' }} />}
              <Typography>Validating Identity</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {progressState.checking === 'inProgress' && <CircularProgress size={24} sx={{color:'#716969'}}/>}
              {progressState.checking === 'success' && <CheckCircle sx={{ color: '#7c9388' }} />}
              {progressState.checking === 'failed' && <Cancel sx={{ color: 'rgba(255, 0, 0, 0.7)' }} />}
              <Typography>Checking Crypto Reserve</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {progressState.submitting === 'inProgress' && <CircularProgress size={24} sx={{color:'#716969'}}/>}
              {progressState.submitting === 'success' && <CheckCircle sx={{ color: '#7c9388' }} />}
              {progressState.submitting === 'failed' && <Cancel sx={{ color: 'rgba(255, 0, 0, 0.7)' }} />}
              <Typography>Submitting Payment to Banks</Typography>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
