import './App.css';
import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Hero from './components/Hero';
import AppAppBar from './components/AppAppBar';
import { Box } from '@mui/material';

function App() {
  const [mode, setMode] = React.useState('light');
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };
  const defaultTheme = createTheme({ palette: { mode } });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline enableColorScheme/>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          // backgroundColor: mode === 'light' ? "#d6f49d" : "7c9388",
        }}
      >
        <object
          type="image/svg+xml"
          data={mode === 'light' ? '/QCBackgroundLight.svg' : '/QCBackgroundDark.svg'}
          className="svg-background"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        >
           Background image
        </object>
          <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
          <Hero />
      </Box>
    </ThemeProvider>
  );
}

export default App;
